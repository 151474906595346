<template>
  <div>
    <div class="flex items-center justify-between w-full mb-8">
      <h2>{{$t('Slots')}}</h2>
      <div class="flex gap-2">
        <p class="font-semibold text-base">{{ $t('Needs Resorting') }}</p>
        <vs-switch class="float-right" v-model="needsResorting"/>
      </div>
    </div>
    <div class="grid xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 gap-4">
      <template v-if="warehouseUnitsLoading">
        <shipblu-card v-for="item in 5" :key="item.index" :cardLoading="true" class="p-5"></shipblu-card>
      </template>
      <template v-else>
        <shipblu-card class="p-4" v-for="warehouseUnit in warehouseUnits" :key="warehouseUnit.index">
          <div @click="getPackages(warehouseUnit)" class="cursor-pointer">
            <div class="relative">
              <span class="absolute left-0">
                <vx-tooltip text="Is Full">
                  <feather-icon icon="LockIcon" svgClasses="h-4 w-4" class="mr-2 text-blue-900" v-if="warehouseUnit.is_full"/>
                </vx-tooltip>
              </span>
              <div class="flex justify-center">
                <p class="text-primary text-base font-semibold">{{warehouseUnit.aisle_sign}}</p>    
              </div>
            </div>
            <div class="flex justify-between mt-4">
              <p class="text-darkgray text-base gap-2">{{$t('No. Packages')}}</p>
              <p class="text-blue-900 text-base text-right font-medium">{{warehouseUnit.num_packages}}</p>
            </div>
            <div class="flex justify-between">
              <p class="text-darkgray text-base gap-2">{{$t('Agent')}}</p>
              <p class="text-blue-900 text-base text-right font-medium">{{warehouseUnit.checkout_agent ? warehouseUnit.checkout_agent.first_name :''}} {{warehouseUnit.checkout_agent ? warehouseUnit.checkout_agent.last_name :''}}</p>
            </div>
            <div class="flex justify-between">
              <p class="text-darkgray text-base gap-2">{{$t('Checkout Date')}}</p>
              <p class="text-blue-900 text-base text-right font-medium">{{warehouseUnit.checkout_date ? new Date(warehouseUnit.checkout_date).toLocaleDateString('fr-CA') : ''}}</p>
            </div>
          </div>
        </shipblu-card>
      </template>
    </div>

    <!-- Packages In Unit View -->
    <packages-in-unit :unitObj="slot" :unitViewActive="slotViewActive"
      @unitViewActive="slotViewActive = $event" @loadUnits="loadWarehousesUnits" @slotsLoading="packagesLoading = $event"></packages-in-unit>
  </div>
</template>

<script>
import {sendRequest} from '../../http/axios/requestHelper.js'
import ShipbluTable from '../../layouts/components/ShipBluTable.vue'
import ShipbluTr from '../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../layouts/components/ShipBluTd.vue'
import ShipbluCard from '../../layouts/components/ShipbluCard.vue'
import i18nData from '../../i18n/i18nData.js'
import PackagesInUnit from './components/PackagesInUnit.vue'

export default {
  data () {
    return {
      maximumItems: process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      warehouseUnits: [],
      selected: [],
      slot: {},
      searchInProgress: false,
      warehouseUnitsLoading: false,
      slotViewActive: false,
      packagesLoading: false,
      packagesInSlot: [],
      openedSlot: {},
      deletedPackage: null,
      needsResorting: false
    }
  },
  watch: {
    'needsResorting' () {
      this.loadWarehousesUnits()
    },
    '$route.params.warehouseID' () {
      this.loadWarehousesUnits()
    }
  },
  methods: {
    getPackages (warehouseUnit) {
      this.slotViewActive = true
      this.slot = warehouseUnit
    },
    loadWarehousesUnits () {
      this.searchInProgress = true
      this.warehouseUnitsLoading = true
      sendRequest(true, false, this, `api/v1/slots/?warehouse__code=${this.$route.params.warehouseID}&limit=250&offset=&search=${this.needsResorting ? '&resort=true' : ''}`, 'get', null, true,
        (response) => {
          this.selected = []
          this.warehouseUnits = response.data.results
          this.totalRows = response.data.count
          this.warehouseUnitsLoading = false
        }
      )
      this.searchInProgress = false
    },
    confirm (slot, deletedPackage) {
      this.slot = slot
      this.deletedPackage = deletedPackage
      this.$vs.dialog({
        color: 'danger',
        title: i18nData[this.$i18n.locale]['Confirm'],
        text: deletedPackage ? i18nData[this.$i18n.locale]['Are you sure to delete this package?'] : i18nData[this.$i18n.locale]['Are you sure to empty this slot?'],
        acceptText: i18nData[this.$i18n.locale]['Yes'],
        accept: deletedPackage ? this.removePackageFromSlot : this.emptySlot
      })
    },
    emptySlot () {
      this.warehouseUnitsLoading = true
      sendRequest(true, false, this, `api/v1/warehousing/slots/${this.slot.id}/empty/`, 'post', {}, true,
        () => {
          this.getPackages(this.openedSlot)
          this.loadWarehousesUnits()
        }
      )
    }
  },
  components: {
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    ShipbluCard,
    PackagesInUnit
  },
  created () {
    this.loadWarehousesUnits()
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
.con-vs-dropdown--menu {
  z-index: 999999;
}
.con-pagination-table{
  display: none;
}
</style>