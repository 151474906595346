<template>
  <div id="slotViewContainer" @click="checkElementClicked" :class="unitViewActive ? 'slot-view-container' : ''">
    <div class="slot-view-card" :class="unitViewActive ? 'show-slot-view-card' : 'hide-slot-view-card'">
      <div class="p-4 flex items-center justify-between">
        <p class="text-primary text-lg font-medium">{{ $route.params.warehouseID + ' - ' + openedUnit.aisle_sign }}</p>
        <feather-icon @click="unitViewActive = false" class="cursor-pointer text-gray" icon="XSquareIcon" svgClasses="h-5 w-5"/>
      </div>
      <vs-divider class="m-0 px-4 pb-2" />
      <div class="content p-4">
        <div v-if="packagesLoading">
          <shipblu-card :cardLoading="packagesLoading" class="p-4 packages-view mb-4" v-for="(item, index) in 4" :key="index"></shipblu-card>
        </div>
        <div v-else>
          <div v-show="packagesInUnit.length > 0 || unitObj.checkout_agent" class="text-right mb-5">
            <vs-button @click="confirm(openedUnit)" color="danger" class="font-semibold px-4">{{ $t('Empty Slot') }}</vs-button>
          </div>
          <shipblu-card class="p-4 mb-4" v-for="(item, index) in packagesInUnit" :key="index">
            <p class="font-medium">
              <span class="text-darkgray">{{ $t('Tracking Number') + ': ' }}</span>
              <span class="text-blue-900">{{ item.tracking_number }}</span>
            </p>
            <p class="mt-1 font-medium">
              <span class="text-darkgray">{{ $t('Zone') + ': ' }}</span>
              <span class="text-blue-900">{{ item.customer.address.zone.name }}</span>
            </p>
            <p class="text-darkgray mt-1 font-medium">{{ $t('Packages IDs') + ': ' }}</p>
            <div class="grid sm:grid-cols-3 grid-cols-2 gap-4 mt-3">
              <div v-for="(element, packageIndex) in item.packages.filter(pack => pack.package_location.aisle_sign !== 'In Counter')" :key="packageIndex">
                <shipblu-card class="px-3 py-2 relative">
                  <vs-button color="#fff" @click="confirm(openedUnit, element.package)" class="card absolute py-1 px-1 flex items-center justify-center cursor-pointer">
                    <feather-icon icon="Trash2Icon" svgClasses="w-4 h-4" class="self-center text-red"/>
                  </vs-button>
                  <p class="text-blue-900 font-medium text-left" style="direction: ltr;">{{Array(String(element.package.id).length - 1).join("*") + String(element.package.id).slice(-2)}}</p>
                </shipblu-card>
              </div>
            </div>
          </shipblu-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {sendRequest} from '@/http/axios/requestHelper.js'
import ShipbluCard from '@/layouts/components/ShipbluCard.vue'
import common  from '@/assets/utils/common'
import AXIOS from '@/http/axios/index.js'
import i18nData from '@/i18n/i18nData.js'

export default {
  props: ['unitViewActive', 'unitObj'],
  data () {
    return {
      packagesLoading: false,
      openedUnit: {},
      packagesInUnit: [],
      slot: {}
    }
  },
  watch: {
    unitObj (val) {
      if (val) {
        this.showPackages(val)
      }
    }
  },
  methods: {
    checkElementClicked (event) {
      if (event.target.classList[0] === 'slot-view-container') {
        this.$emit('unitViewActive', false)
      }
    },
    showPackages (unit) {
      this.openedUnit = unit
      this.$emit('unitViewActive', true)
      this.packagesLoading = true
      sendRequest(true, false, this, `api/v1/warehousing/slots/${unit.id}/packages/`, 'get', null, true, 
        (response) => {
          this.packagesInUnit = response.data.results
          this.packagesLoading = false
        }
      )
    },
    removePackageFromSlot () {
      const packages = this.deletedPackage.id ? [this.deletedPackage.id] : this.packages.map(item => item.package)
      AXIOS.delete(`api/v1/warehousing/slots/${this.slot.id}/packages/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`
        },
        data: {
          packages
        }
      }).then(() => {
        this.$vs.notify({
          color: 'success',
          title: 'Success',
          text: i18nData[this.$i18n.locale]['Package'] + i18nData[this.$i18n.locale][' has been deleted successfully!'],
          position: 'top-center'
        })
        this.showPackages(this.openedUnit)
        this.$emit('loadUnits')
      }).catch((error) => {
        common.notifyResponseError(this, error)
        this.$vs.loading.close()
      })
    },
    confirm (openedUnit, deletedPackage) {
      this.slot = openedUnit
      this.deletedPackage = deletedPackage
      this.$vs.dialog({
        color: 'danger',
        title: i18nData[this.$i18n.locale]['Confirm'],
        text: deletedPackage ? i18nData[this.$i18n.locale]['Are you sure to delete this package?'] : i18nData[this.$i18n.locale]['Are you sure to empty this slot?'],
        acceptText: i18nData[this.$i18n.locale]['Yes'],
        accept: deletedPackage ? this.removePackageFromSlot : this.emptySlot
      })
    },
    emptySlot () {
      this.$emit('slotsLoading', true)
      sendRequest(true, false, this, `api/v1/warehousing/slots/${this.slot.id}/empty/`, 'post', {}, true,
        () => {
          this.showPackages(this.openedUnit)
          this.$emit('loadUnits')
        }
      )
    }
  },
  components: {
    ShipbluCard
  }
}
</script>

<style lang="scss">
.slot-view-container {
  position: fixed;
  inset: 0;
  z-index: 52004;
  background-color: rgba(10, 50, 102, 0.24);
  transition: 0.3s ease;
}
.slot-view-card {
  position: fixed;
  right: 0;
  top: 0;
  background: #FFFFFF;
  box-shadow: 0px 0px 10px 10px rgba(10, 50, 102, 0.24);
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
  width: 400px;
  height: 100vh;
  & > div.content {
    height: calc(100% - 60px);
    overflow-y: auto;
  }
  .card {
    background: #fff;
    border-radius: 4px;
    box-shadow: 0px 2px 8px rgba(157, 173, 194, 0.2);
    &.absolute {
      right: -8px;
      top: -8px;
    }
  }
}
.hide-slot-view-card {
  position: fixed;
  z-index: 52004;
  right: -500px;
  transition: all 0.3s ease;
}
.show-slot-view-card {
  position: fixed;
  z-index: 52004;
  right: 0px;
  transition: all 0.3s ease;
}
@media (max-width: 575px) {
  .slot-view-card {
    width: 310px;
  }
  .hide-slot-view-card {
    right: -410px;
  }
}
.shipblu-card.packages-view {
  .card-loader {
    height: fit-content;
    &::before {
      height: 100px;
    }
  }
}
</style>